<template>
    <div ref="rootElement" class="relative">
        <div class="mb-2 flex justify-between overflow-x-auto gap-2">
            <div class="flex gap-4 items-center">
                <template v-if="showViews">
                    <VDropdown placement="bottom-start">
                        <VTooltip>
                            <icon-btn icon="mdi:table-headers-eye" class="table-btn" />
                            <template #popper>
                                {{ t('objects:contacts_view') }}
                            </template>
                        </VTooltip>
                        <template #popper>
                            <slot name="indexViews"/>
                        </template>
                    </VDropdown>
                    <slot name="nameViews"/>
                    <ver-line/>
                </template>

                <div class="min-w-50">
                    <input-with-icon v-if="showSearch" icon="mdi:search" :placeholder="t('common:search')"
                                     :value="params.search" @change="search" />
                </div>

                <slot name="filters"/>
            </div>

            <div class="flex gap-2">
                <VTooltip v-if="showExport">
                    <icon-btn icon="mdi:file-export"
                              class="table-btn" @click="showExport"/>
                    <template #popper>
                        {{ t('common:export') }}
                    </template>
                </VTooltip>

                <VTooltip v-if="showImport">
                    <icon-btn icon="mdi:database-import-outline"
                              class="table-btn" @click="showImport"/>
                    <template #popper>
                        {{ t('common:import') }}
                    </template>
                </VTooltip>
            </div>
        </div>
        <div class="m-2 flex justify-between overflow-x-auto gap-2">
            <slot name="actions"/>
        </div>
        <div class="overflow-x-auto relative">
            <card-body v-if="smallerThanLg || props.useCardView" :pagination="vPagination" :columns="columns"
                       :card-class="cardClass" @sort="sortByColumn">

                <template v-for="(_, slot) in $slots" #[slot]="scope">
                    <slot :name="slot" v-bind="scope || {}" />
                </template>
            </card-body>
            <table-body v-else :loading="!pagination" :data="vPagination?.data ?? []" :columns="columns"
                        :class="{'under-drag': columnUnderDrag}"
                        @drag:mousedown="putUnderDrag" @sort="sortByColumn">
                <template v-for="(_, slot) in $slots" #[slot]="scope">
                    <slot :name="slot" v-bind="scope || {}" />
                </template>
            </table-body>

            <div v-if="columnUnderDrag" ref="underDragElement" class="absolute z-20 top-0" style="cursor: grabbing"
                 :style="underDragStyles">
                <table-body :loading="!pagination" :data="vPagination?.data ?? []" :columns="[columnUnderDrag]">
                    <template v-for="(_, slot) in $slots" #[slot]="scope">
                        <slot :name="slot" v-bind="scope || {}" />
                    </template>
                </table-body>
            </div>
        </div>

        <div class="flex text-xs mt-4 w-full">
            <small class="flex justify-start items-center w-1/2">
                <dropdown-select v-model="perPage" :options="perPageOptions" class="text-primary"
                                 @update:model-value="(value: Option) => loadAndSave({page:1, limit: value.value as number})"/>
                {{ t('common:per_page') }}
            </small>
            <small v-if="showTail && vPagination" class="flex items-center justify-end w-1/2">
                {{ t('common:tableTail', {total: vPagination.meta.total, limit: vPagination.data.length}) }}
            </small>
        </div>

        <page-numbering v-if="showPageNumbering" :pagination="vPagination"
                        @navigate-to="(page: number) => {loadAndSave({page:page}); $emit('navigateTo', page)}"/>
    </div>
</template>

<script setup lang="ts">
import t from '@lang/t'
import {Pagination} from "@components/tables/Pagination";
import type {Column, FiltersType, OrFilter} from "@components/tables/PrimaryTableTypes";
import PageNumbering from "@components/tables/PageNumbering.vue";
import type { WritableComputedRef} from "vue";
import {nextTick, onMounted, ref, watch} from "vue";
import TableBody from "@components/tables/sub-components/TableBody.vue";
import InputWithIcon from "@components/forms/InputWithIcon.vue";
import VerLine from "@components/VerLine.vue";
import { useUrlSearchParams, useVModel} from "@vueuse/core";
import type {IndexApiType} from "@js/APIs/api";
import {isArray} from 'lodash-es'
import type {Option} from "@components/forms/MultiselectTypes";
import {UrlHelper} from "@js/helpers/UrlHelper";
import DropdownSelect from "@components/forms/DropdownSelect.vue";
import CardBody from "@components/tables/sub-components/CardBody.vue";
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

type PaginationModel = Record<string, any>;

const props = withDefaults(defineProps<{
    columns: Column[],
    // note that pagination is meant to be two-way binding, always use v-model with it.
    pagination: Pagination<PaginationModel> | undefined,
    // if passed, will be used to get the pagination data
    load?: IndexApiType<PaginationModel>,
    prototype?: new(...obj: any[]) => PaginationModel

    filters?: FiltersType,
    complexFilters?: OrFilter[],

    showTail?: boolean,
    showPageNumbering?: boolean,
    showViews?: boolean,
    showSearch?:boolean,
    showFilters?:boolean,
    showExport?:()=>void,
    showImport?:()=>void,
    showPerPage?:boolean,
    useCardView?:boolean,
    cardClass?:boolean,
}>(), {
    showTail: true,
    showPageNumbering: true,
    showPerPage: true,
    useCardView:false
});

const breakpoints = useBreakpoints(breakpointsTailwind)
const smallerThanLg = breakpoints.smaller('lg') // only smaller than lg

const emits = defineEmits(['navigateTo', 'update:columns', 'update:pagination'])

const vPagination = useVModel(props, 'pagination', emits) as WritableComputedRef<undefined|Pagination<PaginationModel>>

const rootElement = ref();
const params = useUrlSearchParams()

let columnUnderDrag = ref<Column>();
let indexUnderTheDrag = ref<number>(0)
let underDragElement = ref<HTMLDivElement>();
let underDragStyles = ref({
    left: 0 + "px",
    width: "100px"
});

const columnPlaceholder: Column = {
    label: '',
    labelObject: {
        id: '_drag_placeholder_column',
        style: 'background:white'
    },
    field: '_drag_placeholder_field'
}

let perPageOptions: Option[] = [
    {
        label: '15',
        value: 15,
    },
    {
        label: '25',
        value: 25,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
]
let urlPerPage = (isArray() ? params.limit[0] : params.limit) as string
let perPage = ref(params.limit ? {label: urlPerPage, value: parseInt(urlPerPage)} : perPageOptions[0])

onMounted(() => {
    if(!props.pagination) {
        loadAndSave({complex_filters: props.complexFilters})
    }
})

if(props.filters)
{
    watch(props.filters, () => {
        if(!props.filters) return;

        loadAndSave({filters: props.filters, page: 1})
    })
}

if(props.complexFilters)
{
    watch(() => props.complexFilters, () => {
        if(!props.complexFilters) return;

        loadAndSave({complex_filters: props.complexFilters, page: 1})
    })
}

type LoadAndSaveType = {
    cursor?:string,
    page?: number|undefined,
    search?: string|number|undefined,
    filters?: FiltersType,
    complex_filters?: OrFilter[],
    limit?: number,
    sort_by?: string|string[]
    direction?: 'asc' | 'desc',
}

function loadAndSave(options: LoadAndSaveType = {})
{
    if(props.load) {
        vPagination.value = undefined;

        let url = new UrlHelper();

        url.setWhen(() => options.hasOwnProperty('page'),'page', options.page)
            .setWhen(() => options.hasOwnProperty('search'), 'search', options.search)
            .setWhen(() => options.hasOwnProperty('filters'), 'filters', options.filters ? url.normalizeFiltersType(options.filters) : {})
            .setWhen(() => options.hasOwnProperty('limit'), 'limit', options.limit)
            .setWhen(() => options.hasOwnProperty('sort_by'), 'order', options.sort_by)
            .setWhen(() => options.hasOwnProperty('direction'), 'direction', options.direction)
            .setComplexFilters('complex_filters', options.complex_filters)
            .save()

        return props.load(url.unserialize()).then((response) => {
            vPagination.value = new Pagination(response.data, props.prototype)
        })
    }
}

function search(e: Event)
{
    let input = e.target as HTMLInputElement;
    loadAndSave({
        search: input.value,
        page: 1 // always reset the page when making new search
    })
}
function putUnderDrag({event, column, columnIndex}: {event:MouseEvent, column: Column, columnIndex:number})
{
    columnUnderDrag.value = column;
    indexUnderTheDrag.value = columnIndex

    if(columnPlaceholder.labelObject) {
        let columns = props.columns.filter(column => column.field !== columnUnderDrag.value?.field)
        columns.splice(indexUnderTheDrag.value, 0, columnPlaceholder)
        emits('update:columns', columns)
    }

    nextTick(() => {
        underDragStyles.value.width = document.getElementById('_drag_placeholder_column')?.offsetWidth + "px";

        nextTick(() => {
            calculateDragElementLeft(event)

            nextTick(() => {
                calculatePlaceholderIndex()
                document.addEventListener('mousemove', onDragModeMouseMove)
                document.addEventListener('mouseup', onDragModeMouseUp)
            })
        })
    })
}

function onDragModeMouseUp()
{
    document.removeEventListener('mousemove', onDragModeMouseMove)

    if(columnUnderDrag.value) {
        let columns = props.columns.filter(column => column.field !== columnPlaceholder.field)
        columns.splice(indexUnderTheDrag.value, 0, columnUnderDrag.value)
        emits('update:columns', columns)

        columnUnderDrag.value = undefined;
        indexUnderTheDrag.value = 0;
    }
}
function onDragModeMouseMove(event: MouseEvent)
{
    calculateDragElementLeft(event);
    calculatePlaceholderIndex();
}
function calculateDragElementLeft(event: MouseEvent)
{
     // mouseX, rootRect, width
    let rootRect = rootElement.value.getBoundingClientRect();
    let x = event.clientX - rootRect.left; //x position of the mouse relative to the table edge

    let width = parseInt(underDragStyles.value.width);
    let left = (x - width) + "px";
    // 0.5 is the offset of the drag icon from the edge
    underDragStyles.value.left = `calc(${left} + 0.5rem)`
}

function calculatePlaceholderIndex()
{
    if(!columnPlaceholder.labelObject?.id) return;

    let placeholderRect = document.getElementById(columnPlaceholder.labelObject.id)?.getBoundingClientRect()
    let draggedElRect = underDragElement.value?.getBoundingClientRect()

    if(placeholderRect && draggedElRect) {
        let increase = 0;

        if(placeholderRect.right < draggedElRect.left) increase = -1
        if(placeholderRect.left > draggedElRect.right) increase = 1

        if(increase !== 0) {
            let columnUnderTheDrag = props.columns[indexUnderTheDrag.value + increase];
            if(columnUnderTheDrag.draggable) {
                indexUnderTheDrag.value += increase;
                let columns = props.columns.filter((column) => column.field !== columnPlaceholder.field)
                columns.splice(indexUnderTheDrag.value, 0, columnPlaceholder)
                emits('update:columns', columns)
            }
        }
    }
}

function sortByColumn({column, direction}: { column: Column, direction: 'asc' | 'desc' })
{
    if(!column.sort) return;

    loadAndSave({
        sort_by: column.sortBy ?? column.field,
        page: 1,
        direction: direction
    })
}

</script>

<style lang="scss">
.under-drag {
    table-layout: fixed;
    @apply select-none pointer-events-none;
    td {
        @apply b-2;
    }
}

.table-btn {
    @apply bg-gray-100 hover:bg-gray-600 hover:text-white transition p-2 rounded text-xl duration-300;
}

.per-page-dropdown {
    li {
        cursor: pointer;
        @apply transition duration-300;
        &:hover {
            @apply text-primary-dark;
        }
    }
}

.v-popper--theme-menu {
    width: inherit;
}
</style>

<template>
    <div class="w-full border ">
        <div :class="'card flex flex-wrap gap-5 bg-light'+ (cardClass?' flex-col':'')">
            <template v-if="pagination && pagination.meta.total > 0">
                <div v-for="(item, rowIndex) in pagination.data" :key="rowIndex"
                     :class="'bg-white shadow-lg rounded-lg max-h-96 overflow-y-auto p-2'
                         + (cardClass? ' max-w-full':' max-w-xs w-120')">

                    <slot name="_card" :item="item" :row-index="rowIndex">
                        <card-content v-for="column in columns" :key="column.field" :index="rowIndex"
                                      :column="column" :row="item" @sort="handleSort">
                            <template v-for="(_, slot) in $slots" #[slot]="scope">
                                <slot :name="slot" v-bind="scope || {}"/>
                            </template>
                        </card-content>
                    </slot>
                </div>
            </template>

            <div v-else-if="!pagination" class="text-center">
                <div class="py-2">
                    {{ t('common:loading') }} ...
                </div>
            </div>

            <div v-else class="text-center">
                <div class="py-2">
                    {{ t('common:no_data') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import t from "@lang/t";
import type {Column} from "@components/tables/PrimaryTableTypes";
import type {Pagination} from "@components/tables/Pagination";
import {ref} from "vue";
import {UrlHelper} from "@js/helpers/UrlHelper";
import CardContent from "@components/tables/sub-components/CardContent.vue";

defineProps<{
    columns: Column[],
    pagination: Pagination<Record<string, any>> | undefined,
    cardClass?:boolean,
}>();
const emits = defineEmits(['sort'])

let sortingDirection = ref<'asc' | 'desc'>('asc')
let urlHelper = new UrlHelper()

let currentSorting = ref<string | undefined>(urlHelper.get('order') ?? undefined)

function handleSort(column: Column) {
    if (!column.sort) return;

    if (currentSorting.value === column.field) {
        sortingDirection.value = sortingDirection.value === 'asc' ? 'desc' : 'asc';
    }

    currentSorting.value = column.field;

    emits('sort', {column, direction: sortingDirection.value})
}
</script>

<style scoped lang="scss">
.sort-btn {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    margin-top: -1rem;
    transition: all 300ms;
}

.sort-btn, .sort-btn.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}
.w-inherit{
    width: inherit;
}

</style>

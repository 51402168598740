import type {ID} from "@js/APIs/api";
import {get, keyBy, mergeWith, values} from "lodash-es";
import t from "@lang/t";

export type Link = {
    url: string
    label: string
    active: boolean
}

export type Navigation = {
    first: string
    last: string
    prev: string|null
    next: string|null
}

export type Meta = {
    current_page: number
    from: number
    last_page: number
    links: Link[]
    path: number
    per_page: number
    to: number
    total: number
}

export interface LengthAwarePagination<T extends object> {
    data: T[],
    links: Navigation,
    meta: Meta
}

export type PaginationDataObject = Record<string|number, any>;

export abstract class AbstractDataPagination<T extends PaginationDataObject> {

    public data: T[]

    hasData()
    {
        return this.data.length > 0;
    }

    findById(id: ID, key = 'id')
    {
        return this.data.find((item) => {
            if(item.hasOwnProperty(key) && item[key] === id) {
                return item
            }
        })
    }

    append(item: T)
    {
        this.data.unshift(item)
    }

    insert(item: T, index: number = 0)
    {
        this.data.splice(index, 0 , item)
    }

    push(item: T)
    {
        this.data.push(item)
    }

    updateById(id: ID, newObject: T, key = 'id')
    {
        this.data = this.data.map((item) => {
            if(item.hasOwnProperty(key) && item[key] === id) {
                return newObject
            }

            return item
        })
    }

    delete(item: T)
    {
        this.data = this.data.filter((current) => {
            return current !== item
        })
    }

    deleteById(id:ID|ID[], key='id'){

        let ids = Array.isArray(id) ? id : [id]
        this.data = this.data.filter((current) => {
            return !ids.includes(get(current, key))
        })
    }

    abstract hasMorePages(): boolean;
}

export class Pagination<T extends PaginationDataObject> extends AbstractDataPagination<T> implements LengthAwarePagination<T>{
    public data: T[]
    public links: Navigation
    public meta: Meta

    /**
     *
     * @param page
     * @param prototype - transfer the data to a prototype, allow us to use classes
     */
    constructor(page: LengthAwarePagination<T>, prototype?: new(...obj: any[]) => T) {
        super();

        this.data = prototype ?
            page.data.map(obj => {
                    return new prototype(obj)
            }) : page.data;

        this.links = page.links;
        this.meta = page.meta;
    }

    append(item: T)
    {
        super.append(item);
        this.updateTotal()
    }

    insert(item: T, index: number = 0)
    {
        super.insert(item, index)
        this.updateTotal()
    }

    push(item: T)
    {
        super.push(item)
        this.updateTotal()
    }

    delete(item: T)
    {
        super.delete(item)
        this.updateTotal()
    }

    deleteById(ids:ID|ID[], key='id'){

        super.deleteById(ids, key)

        this.updateTotal()
    }

    updateTotal()
    {
        this.meta.total = this.data.length;
    }

    hasMorePages()
    {
        return !!this.links.next
    }

    merge(data: T[], key = 'id') {
        const mergedData = mergeWith(
            keyBy(data, key),
            keyBy(this.data, key),
            (objValue, srcValue) => {
                return { ...objValue, ...srcValue };
            }
        );
        return values(mergedData)
    }
}

export class MemoryPagination<T extends PaginationDataObject> extends Pagination<T>{
    constructor(page: LengthAwarePagination<T>, prototype?: new(...obj: any[]) => T) {
        super(page, prototype);
        this.updatePaginationLinks()
    }

    append(item: T) {
        super.append(item);
        if (this.meta.last_page!== Math.ceil(this.meta.total / this.meta.per_page)){
            this.updatePaginationLinks()
        }
    }

    delete(item: T) {
        super.delete(item);
        if (this.meta.last_page!== Math.ceil(this.meta.total / this.meta.per_page)){
            this.updatePaginationLinks()
        }
    }

    getPageData(){
        const startIndex = (this.meta.current_page - 1) * this.meta.per_page;
        const endIndex = Math.min(startIndex + this.meta.per_page, this.meta.total);
        return this.data.slice(startIndex, endIndex);
    }

    updatePaginationLinks() {
        const total = this.meta.total;
        const perPage = this.meta.per_page;
        const currentPage = this.meta.current_page;
        const totalPages = this.meta.last_page = Math.ceil(total / perPage);

        let link: Link = {
            url: (currentPage>1)? (currentPage-1).toString():'',
            label: "&laquo; "+t('common:previous'),
            active:false,
        };
        const links: Link[] = [
            link
        ];

        for (let i = 1; i <= totalPages; i++) {
            const link: Link = {
                url: i.toString(),
                label: i.toString(),
                active: i === currentPage,
            };

            links.push(link);
        }
        link = {
            url: (totalPages > currentPage)? (currentPage+1).toString():"",
            label: t('common:next')+" &raquo;",
            active: false,
        };
        links.push(link)

        this.meta.links = links;
    }
}

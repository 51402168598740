<template>
    <div class="">
        <nav class="m-auto text-center">
            <ul v-if="pagination" class="inline-flex text-sm border border-gray-300 rounded">
                <li v-for="link in pagination.meta.links" :key="link.label">
                    <a class="flex items-center justify-center px-3 h-8
                            text-gray-600 bg-white hover:bg-gray-200 hover:text-gray-800 cursor-pointer" :class="{'!bg-primary !text-white':link.active, 'cursor-not-allowed !text-gray-400': !link.url}"
                       @click="navigateTo(link)"
                       v-html="link.label"/>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import type {Pagination} from "@components/tables/Pagination";
import type {Link} from "@components/tables/Pagination";

defineProps<{
    pagination?: Pagination<Record<string, any>>
}>()

const emits = defineEmits(['navigateTo'])

function navigateTo(link: Link)
{
    if(link.active || !link.url) return;

    let page = link.url.split('page=').pop();

    if(page) {
        emits('navigateTo', parseInt(page))
    }
}
</script>

<style scoped lang="scss"></style>

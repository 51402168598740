export default {
    'event': 'Event',
    'events': 'Events',
    'workflow': 'Workflow',
    'workflows': 'Workflows',
    'workflows_desc': 'Workflow diagrams automate various processes, reducing manual effort and increasing work efficiency.',
    'new_workflow': 'New Workflow',
    'conditions': 'Conditions',
    'actions': 'Actions',
    'enter_workflow_name': 'Enter Workflow Name',

    'MessageReceived': 'Message Received',
    'MessageSent': 'Message Sent',
    'MessageSentDesc': "Triggered when a message is sent to a contact, doesn't trigger on broadcasts or tag messages.",
    'selectEvent': 'Select Event',
    'messages': 'Messages',

    'contact_created': 'Contact Created',
    'contact_updated': 'Contact Updated',
    'contact_deleted': 'Contact Deleted',

    'true': 'True',
    'false': 'False',

    // MessageReceivedConditions
    'containError': 'Contain Error',
    "hasAttachment": 'Has attachment',
    "hasAttachedContact": 'Has attached contact',
    "isChannel": 'From channel type',
    "hasLocation": 'Has Location',
    "contains": 'Contains',
    "endsWith": 'Ends with',
    "isPattern": 'Is equals',
    "isAscii": 'Is ASCII',
    "isUrl": 'Is URL',
    "startsWith": 'Starts with',
    "wordCount": 'Word count equals',
    "isReply": 'Is a reply',
    "fromPlatform": 'From following channel',
    "isRead": 'Is read',
    "hasReaction": 'Has reaction',
    "statusIs": 'Status equals',
    "isFromNewContact": 'Is new contact',

    // parameters name
    'needle': 'Needle',
    'value': 'Value',
    'operator': 'Operator',

    // actions
    'delay': 'Delay',
    'sendCannedResponse': 'Send Canned Response',
    'createContact': 'Create Contact',

    // units
    'second': 'Second',
    'minute': 'Minute',
    'hour': 'Hour',
    'day': 'Day',
}

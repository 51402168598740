<template>
    <form @submit.prevent="submit">
        <slot/>
    </form>
</template>

<script setup lang="ts">
import api from "@js/APIs/api";
import {computed, provide, ref} from "vue";
import type {AxiosError} from "axios";

const props = withDefaults(defineProps<{
    url?: string,
    action?: 'post' | 'put',
    processing?: boolean|undefined
}>(), {
    action: 'post',
    processing: undefined
})

const emits = defineEmits(['submit', 'succeed', 'failed', 'update:processing'])

let localProcessing = ref(false)
// this processing will be injected to all descendants buttons
let combinedProcessing = computed({
    get() {
        return props.processing !== undefined ? props.processing : localProcessing.value
    },
    set(value: boolean) {
        localProcessing.value = value
        emits('update:processing', value)
    }
})

provide('processing', combinedProcessing)

function submit(event: Event) {
    const formData = new FormData(event.target as HTMLFormElement);
    combinedProcessing.value = true

    emits('submit', formData, event)

    if (!props.url) {
        return;
    }

    // todo we shouldn't use this anymore,
    // you will need to define 2 emits for this approach instead of one. use submit instead
    api({
            method: props.action,
            url: props.url,
            data: formData
        }).then((data) => {
            emits('succeed', data)
        })
        .catch((data: AxiosError) => {
            emits('failed', data)
        })
        .finally(() => {
            combinedProcessing.value = false
        })
}
</script>

<style scoped lang="scss"></style>

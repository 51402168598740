import {defineStore} from "pinia";
import TelescopeAPIs from "@js/APIs/TelescopeAPIs";

export const useErrorStore = defineStore('errors',{
    state: () => ({
        errors: [] as Error[]
    }),
    actions: {
        addError(errorData: Error, info: string, url: string, data?: object) {
            const existingError = this.errors.find(error =>
                error.stack === errorData.stack &&
                error.message === errorData.message &&
                error.cause === errorData.cause &&
                error.name === errorData.name
            );
            if (!existingError) {
                this.errors.push(errorData);
                TelescopeAPIs.saveError({
                    info: info,
                    url:url,
                    error: {
                        name: errorData.name,
                        cause: errorData.cause,
                        stack: errorData.stack,
                        message: errorData.message,
                    },
                    data: data
                }).catch();
            }
        },
    }
})

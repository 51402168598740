import {isArray, isPlainObject} from "lodash-es";
import type {FiltersType, FilterType, OrFilter} from "@components/tables/PrimaryTableTypes";
import qs from "qs";
import type {Option} from "@components/forms/MultiselectTypes";
import type {ApiFilterType} from "@js/APIs/api";

export type UrlParamType = string | number | Record<string, number|string|string[]> | string[];
export class UrlHelper {
    public url;
    private _parsedQs: Record<string, any>;
    constructor(url=window.location.href) {
        this.url = new URL(url)
    }

    get parsedQs()
    {
        if(!this._parsedQs) {
            this._parsedQs = qs.parse(this.url.search, { ignoreQueryPrefix: true })
        }

        return this._parsedQs
    }

    get(name: string, defaultValue: undefined|unknown = undefined) {
        return this.parsedQs[name] ?? defaultValue
    }

    setWhen(condition: () => boolean, name: string, value: UrlParamType | undefined | null)
    {
        if(condition()) {
            this.set(name, value)
        }

        return this;
    }

    setComplexFilters(key: string, complexFilters?: OrFilter[])
    {
        /*
            complex_filters[0][0][field]=field&complex_filters[0][0][operation]=equal&complex_filters[0][0][value]=value
            and
            complex_filters[0][1][field]=field1&complex_filters[0][1][operation]=equal1&complex_filters[0][1][value]=value1
            or
            complex_filters[1][0][field]=field&complex_filters[1][0][operation]=equal&complex_filters[1][0][value]=value
         */

        // delete the old complex filters first to prevent appending instead of setting
        this.delete(key)

        complexFilters?.forEach((orFilter, orFilterIndex) => {
            orFilter.forEach((andFilter, andFilterIndex) => {
                this.url.searchParams.set(`${key}[${orFilterIndex}][${andFilterIndex}][field]`, andFilter.field)
                this.url.searchParams.set(`${key}[${orFilterIndex}][${andFilterIndex}][operation]`, andFilter.operation)
                if(andFilter.value) {
                    this.url.searchParams.set(`${key}[${orFilterIndex}][${andFilterIndex}][value]`, this.normalizeFilterType(andFilter.value))
                }
            })
        })

        return this;
    }

    normalizeFiltersType(filtersTypes: FiltersType)
    {
        let result: ApiFilterType = {};

        // let keys = Object.keys(props.filters)
        for (let key in filtersTypes) {
            let value = filtersTypes[key];

            if(value) {
                result[key] = this.normalizeFilterType(value)
            }
        }

        return result;
    }

    normalizeFilterType(filterType: FilterType)
    {
        if (typeof filterType === 'string') {
            return filterType
        }

        if(isPlainObject(filterType)) {
            let stringValue = (filterType as Option)['value'];
            if(stringValue) {
                return stringValue.toString()
            }
        }

        if (typeof filterType === 'number') {
            return filterType.toString()
        }

        throw new Error('filter type normalization is still not implemented ')
    }

    set(name:string, value: UrlParamType | undefined | null)
    {
        if(value === undefined || value === null) {
            this.delete(name)
            return this
        }

        if(typeof value === 'string' || typeof value === 'number') {
            this.delete(name)
            this.url.searchParams.set(name, value.toString())
            return this;
        }

        if(isArray(value)) {
            // delete it first, to delete all nested of it.
            this.delete(name)
            this._setArray(name, value)
            return this;
        }

        if(isPlainObject(value)) {
            // delete it first, to delete all nested of it.
            this.delete(name)

            let entries = Object.entries(value);
            for (const [key, entry] of entries) {
                if(isArray(entry)) {
                    this._setArray(key, entry)
                } else {
                    this.url.searchParams.set(name + `[${key}]`, entry.toString())
                }
            }

            return this;
        }

        return this;
    }

    console()
    {
        for (const [key, entry] of this.url.searchParams.entries()) {
            // eslint-disable-next-line no-console
            console.info(key ,entry)
        }
    }

    _setArray(name:string, value: string[]) {
        for (let i = 0; i < value.length; i++) {
            this.url.searchParams.set(name + `[${i}]`, value[i])
        }
        this.url.searchParams.sort()
    }

    delete(name: string) {
        let keys = this.url.searchParams.keys();
        let regex = `\\b${name}\\[[^\\]]*\\]`;

        let toRemove = [];
        for (const key of keys) {
            if(key === name || new RegExp(regex).test(key)) {
                toRemove.push(key)
            }
        }

        toRemove.forEach((key) => {
            this.url.searchParams.delete(key)
        })
    }

    unserialize()
    {
        return this.parsedQs;
    }

    save() {
        window.history.replaceState(
            window.history.state,
            window.document.title,
            decodeURI(this.url.href)
        )
    }
}

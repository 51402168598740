<template>
    <teleport to="body">
        <Fade :duration="0.3" :delay-out="0.2">
            <div v-show="modelValue"
                 class="modal-overlay fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50"
                 :class="overlayClass"
                 @click.self="() => {if(closeOnOutsideClick) close()}">
                <Zoom :delay-in="0.2">
                    <Card v-if="modelValue" id="modal-card"
                          v-bind="$attrs"
                          class="inline-block max-h-screen" tabindex="0"
                          :class="{'overflow-y-auto': shouldEnableScrolling}" aria-dialog>
                        <template v-if="$slots.header" #header>
                            <slot name="header" />
                        </template>

                        <slot />

                        <template v-if="$slots.footer" #footer>
                            <slot name="footer" />
                        </template>
                    </Card>
                </Zoom>
            </div>
        </Fade>
    </teleport>
</template>

<script setup lang="ts">

import Card from "@components/Card.vue";
import Zoom from "@js/transitions/Zoom.vue";
import Fade from "@js/transitions/Fade.vue";
import {defineOptions, nextTick, onUpdated, ref, watch} from "vue";
import {useLayoutStore} from "@js/stores/Layout";

defineOptions({
    inheritAttrs: false
})

const props = withDefaults(defineProps<{
    modelValue: boolean, focusingItem?: HTMLElement
    closeOnOutsideClick?: boolean,
    overlayClass?: string
}>(), {closeOnOutsideClick: false})

const emits = defineEmits(['update:modelValue'])

let layoutSymbol = Symbol()
let layoutStore = useLayoutStore()

// the reason for this is enabling overflow-y directly will prevent the multiselect dropdown from getting out of
// the modal
let shouldEnableScrolling = ref(false);

const resizeObserver = new ResizeObserver(() => {
    // use ID because ref will not work as there is a v-if in the element
    let modalEl = document.getElementById('modal-card');

    shouldEnableScrolling.value = (modalEl?.scrollHeight ?? 0) >= window.innerHeight;
});

watch(() => props.modelValue, (newValue) => {
    if(newValue) {
        nextTick(() => {
            let modalEl = document.getElementById('modal-card');
            if(modalEl) {
                resizeObserver.observe(modalEl);
            }
        })
    }

    newValue ? layoutStore.add(layoutSymbol) : layoutStore.remove(layoutSymbol)
})

onUpdated(() => {
    nextTick(() => {
        const itemToFocus = props.focusingItem ?? document.getElementById('modal-card');
        itemToFocus!.focus()
    })
})

function close()
{
    emits('update:modelValue', false)
}
</script>

<style scoped lang="scss">
.modal-overlay {
    background: rgba(0, 0, 0, 0.7);
}
</style>

export default {
    'event': 'الحدث',
    'events': 'الأحداث',
    'workflow': 'سير عمل',
    'new_workflow': 'سير عمل جديد',
    'workflows': 'أتمتة العمليات',
    'workflows_desc': 'تقوم مخططات سير العمل بتشغيل العمليات المُختلفة بشكل آلي، مما يقلل الجهد اليدوي ويزيد من كفاءة العمل.',
    'conditions': 'الشروط',
    'actions': 'الإجراءات',
    'enter_workflow_name': 'أدخل اسم سير العمل',

    'selectEvent': 'إختر حدث',
    'messages': 'الرسائل',

    'true': 'صائب',
    'false': 'خاطئ',

    // events
    'MessageReceived': 'رسالة مُستلمة',
    'MessageSent': 'رسالة مُرسلة',
    'MessageSentDesc': "تُثار عند إرسال رسالة إلى جهة اتصال، لا يتم تفعيلها في رسائل البث أو رسائل الوسوم.",

    'contact_created': 'إنشاء عميل',
    'contact_updated': 'تعديل عميل',
    'contact_deleted': 'جذف عميل',

    // Message Received Condition
    "containError": "يحتوي على خطأ",
    "hasAttachment": "يحتوي على مرفق",
    "hasAttachedContact": "يحتوي على جهة اتصال مرفقة",
    "isChannel": "نوع القناة",
    "hasLocation": "يحتوي على موقع",
    "contains": "يحتوي على",
    "endsWith": "ينتهي بـ",
    "isPattern": "يساوي",
    "isAscii": "محتوى بنظام ASCII",
    "isUrl": "هو رابط موقع",
    "startsWith": "يبدأ بـ",
    "wordCount": "عدد الكلمات يساوي",
    "isReply": "هو رد",
    "fromPlatform": "من القناة التالية",
    "isRead": "تم قراءته",
    "hasReaction": "يحتوي على رد فعل",
    "statusIs": "الحالة تساوي",
    "isFromNewContact": "هو جهة اتصال جديدة",

    // parameters name
    'needle': 'القيمة',
    'value': 'القيمة',
    'operator': 'المُعامل',

    // actions
    'delay': 'تأخير',
    'sendCannedResponse': 'أرسل رد مُسبق',
    'createContact': 'أنشئ جهة إتصال',

    // units
    'second': 'ثانية',
    'minute': 'دقيقة',
    'hour': 'ساعة',
    'day': 'يوم',
}
